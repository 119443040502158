<template>
    <div class="order-content">
        <div class="tab-bar">
            <div :class="active === 0 ? 'order active' : 'order'" @click="switchTab(0)">订单详情</div>
            <div :class="active === 1 ? 'tracing active' : 'tracing'" @click="switchTab(1)">商品溯源</div>
        </div>
        <div class="order-detail" v-if="active === 0">
            <div class="content">
                <div class="order-time">{{orderDetail.createTime}}</div>
                <div class="item-records">
                    <div class="record" v-for="(record, index) in orderDetail.data" :key="index">
                        <div class="good-detail">
                            <div class="good-name">{{record.goodsName}}</div>
                            <div class="spec">{{record.goodsPrice}}元/{{record.unit}} <span style="padding:0 0.08rem;"><img src="../../../assets/images/icon-multiply.png" alt="" style="width:.18rem;height:.17rem"></span>{{record.buyNumber}}</div>
                        </div>
                        <div class="price">￥<span>{{record.goodsPrice * 100 * record.buyNumber * 100 / 10000 }}</span></div>
                    </div>
                </div>
                <div class="pay-total">
                    支付金额(元)：<span>{{orderDetail.orderMoney}}</span>
                </div>
            </div>
        </div>
        <div class="tracing-detail" v-if="active === 1">
          <div class="st-wrapper register-wrapper">
            <div class="st-item">
              <div class="st-item-header">进场商户</div>
              <ul class="register-items">
                <li>申请人：{{userInfo.type === 1 ? userInfo.name : user_info.tel}}</li>
                <li>档口号：{{userInfo.type === 1 ? userInfo.customCode : '无'}}</li>
              </ul>
            </div>
            <div class="st-item">
              <div class="st-item-header">进场信息</div>
              <ul class="register-items" v-for="(trace, index) in orderDetail.data" :key="index" style="border-bottom: .01rem solid #eee;">
                <li>进场时间：{{trace.time}}</li>
                <li>产品品名：{{trace.goodsName}}</li>
                <li>运送车牌号：{{trace.licensePlateNumber}}</li>
                <li>产品产地：{{trace.placeOfOrigin}}</li>
                <li>进场数量：{{trace.numberOfBranches}}</li>
                <li>产地检疫证号：{{trace.inspectionAndQuarantine}}</li>
              </ul>
            </div>
            <div class="st-item">
              <div class="st-item-header">安心检测</div>
              <ul class="image-items">
                <li
                  v-for="(item,index) in images"
                  :key="index"
                  @click="imageShow">
                    <img :src="item" alt="">
                </li>
              </ul>
            </div>

            <van-image-preview
              v-model="imagePreviewShow"
              :images="images">
            </van-image-preview>
          </div>
        </div>
        <footer-btn 
            text='返回'
            @confirm='back'
        />
    </div>
</template>

<script>
import { ImagePreview } from 'vant'
import FooterBtn from '../../../components/footer/button/Index'
export default {
    name: '',
    components: {
        [ImagePreview.Component.name]: ImagePreview.Component,
        FooterBtn
    },
    data () {
        return {
            active: 0,
            orderDetail: {},
            userInfo: {},
            images: [],
            imagePreviewShow: false
        }
    },
    created () {
      const orderId = this.$route.params.id
      this.$api.userOrder.detail({orderId:orderId}).then((res) => {
          this.orderDetail = res.data.map
          res.data.map.data.forEach((item) => {
            const arr = item.inspectionAndQuarantineList.split(',')
            this.images = this.images.concat(arr)
          })
          
      }).then(() => {
          this.$api.users.profiles({id:this.orderDetail.merchId}).then(res => {
            this.userInfo = res.data.result
          })
      })
      
    },
    methods: {
        switchTab (active) {
            this.active = active
        },
        imageShow () {
          this.imagePreviewShow = true
        },
        back () {
          this.$router.go(-1)
        }
    }
}
</script>

<style lang="less" scoped>
@import url('./Index.less');
</style>